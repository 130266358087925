.wrapper {
  background: var(--light);
  /* // background: #222; */
  padding: 50px 0;
  color: white;
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  place-content: space-around;
}

.block {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;

  & .blockItem {
    font-size: 20px;
    font-weight: 300;
    height: 50px;
    border: none;
    appearance: none;
    background: black;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    place-items: center;
    place-content: space-between;
    min-width: 200px;

    & i {
      margin-left: 10px;
    }
  }
}

.title {
  /* // color: white; */
  text-transform: uppercase;
  align-self: center;

  font-family: var(--fontHeading);
  text-align: var(--hAlign);
  font-size: var(--h2Size);
  font-weight: var(--h2Weight);
  color: var(--primary);
  margin-bottom: var(--spacing2);
}

.text {
  font-size: var(--pSize);
  font-weight: var(--pWeight);
  color: var(--dark);
  line-height: 1.5;
  text-align: var(--pAlign);
  font-family: var(--fontBody);
  margin-top: 0;
  margin-bottom: var(--spacing3);
  text-align: justify;
  text-align-last: center;
}

.form {
  display: flex;
  flex: 0 1 900px;
  flex-flow: row wrap;
  place-content: center;
  gap: 10px;
  /* // max-width: 700px; */
}

.formItem {
  flex: 1 0 340px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  place-items: stretch;
  transition: 0.1s;

  &[data-is-max-width='true'] {
    flex: 1 1 100%;
  }
}

.formInput {
  background: rgba(0, 0, 0, 0.05);
  height: 50px;
  border: none;
  appearance: none;
  padding: 15px 15px;
  line-height: 1.3;
  display: flex;
  flex-direction: row;
  place-items: center;
  transition: 0.2s;
  color: black;
  font-size: 16px;
  font-weight: 300;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    transition: 0.4s;
  }

  &:focus {
    outline: none;
    background: rgba(0, 0, 0, 0.1);

    &::placeholder {
      color: transparent;
    }
  }
}

textarea.formInput {
  height: 100px;
  resize: none;
}

.formButton {
  height: 50px;
  border: none;
  appearance: none;
  background: var(--primary);
  color: white;
  padding: 0 30px;
  width: fit-content;
  min-width: 250px;
  display: flex;
  place-content: center;
  place-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
  place-self: center;
  /* border-radius: 4px; */
  margin-top: 10px;
  font-weight: 300;
  font-family: var(--fontHeading);
  user-select: none;

  &[data-is-disabled='true'] {
    pointer-events: none;
    opacity: 0.3;
  }

  &:focus {
    outline: none;
    background: var(--primary);
    filter: brightness(1.1);
  }

  &:hover {
    filter: brightness(1.1);
  }
}
