.wrapper {
  @apply relative;

  background: white;

  display: flex;
  place-content: center;
  place-items: center;

  margin-bottom: var(--spacing5);
  background: var(--lighter);
  overflow: hidden;
  padding: 0 0 var(--spacing6);
}

.title {
  text-align: var(--hAlign);
  font-family: var(--fontHeading);
  font-size: var(--h1Size);
  font-weight: var(--h1Weight);
  color: var(--dark);
  background: white;
  /* mix-blend-mode: difference; */
  text-transform: uppercase;
  /* position: absolute; */
  z-index: 10;
  top: 0;
  margin: 0 var(--spacing3);
  max-width: 1000px;

  /* bottom: -10px; */
  padding: var(--spacing2) var(--spacing3);
}

.images {
  @apply absolute flex flex-row;
  height: 100%;
  width: 50%;
  overflow: hidden;
  z-index: 1;
  gap: 5px;
  background: var(--light);
  right: 0;
  top: 0;
}

/* .image {
  @apply flex-1 bg-light rotate-[0deg] h-[600px] mt-[-100px];
  overflow: hidden;

  & img {
    @apply absolute top-[0] bottom-[0] right-0;
    @apply object-cover object-center rotate-[-0deg] scale-150;
    height: 100%;
    width: 100%;
    filter:brightness(0.5);
  }

  & + & {

  }
} */
