.wrapper {
  @apply my-5;

  &[data-background-color] {
    @apply p-6;
    background-color: var(--light);
  }

  &[data-is-small-bottom-margin='true'] {
    @apply -mb-3;
  }

  & p {
    text-align: justify;
    text-align-last: center;
  }

  & ul {
    @apply pl-3 my-2;
    list-style-type: circle;

    & li {
      margin: var(--spacing0_5) 0;
    }
  }
}

.button {
  @apply mt-4;
  place-self: center;
}
