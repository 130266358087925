@tailwind base;
@tailwind components;
@tailwind utilities;

@custom-media --mobile (width < 768px);
@custom-media --tablet (768px <= width < 1024px);
@custom-media --desktop (1024px <= width);

@custom-media --mobileMax (width < 768px);
@custom-media --tabletMax (width < 1024px);

@custom-media --tabletMin (768px <= width);
@custom-media --desktopMin (1024px <= width);

:root {
  --primaryDark: hsl(191, 70%, 10%);
  --primary-2: hsl(191, 70%, 19%);
  --primary-1: hsl(191, 70%, 29%);
  --primary: hsl(191, 70%, 39%);
  --primary1: hsl(191, 70%, 49%);
  --primary2: hsl(191, 70%, 59%);
  --primaryLight: hsl(191, 70%, 90%);

  --primaryDark: hsl(191, 10%, 10%);
  --primary-2: hsl(191, 10%, 19%);
  --primary-1: hsl(191, 10%, 29%);
  --primary: hsl(191, 10%, 39%);
  --primary1: hsl(191, 10%, 49%);
  --primary2: hsl(191, 10%, 59%);
  --primaryLight: hsl(191, 10%, 90%);

  --secondaryDark: hsl(173, 58%, 10%);
  --secondary-2: hsl(173, 58%, 23%);
  --secondary-1: hsl(173, 58%, 33%);
  --secondary: hsl(173, 58%, 43%);
  --secondary1: hsl(173, 58%, 53%);
  --secondary2: hsl(173, 58%, 63%);
  --secondaryLight: hsl(173, 58%, 90%);

  --dark: hsl(0, 0%, 5%);
  --light: hsl(0, 0%, 95%);
  --lighter: hsl(0, 0%, 97.5%);

  --body: var(--dark);
  --primary: var(--dark);

  --fontPrimary: 'Lexend', 'Source Sans Pro', 'Helvetica Neue', sans-serif, Helvetica, Arial;
  --fontText: 'Lexend', 'Source Sans Pro', 'Helvetica Neue', sans-serif, Helvetica, Arial;
  --fontHeading: 'Teko', 'Source Sans Pro', 'Helvetica Neue', sans-serif, Helvetica, Arial;

  --spacing: 10px;
  --spacing0: calc(var(--spacing) * 0);
  --spacing0_5: calc(var(--spacing) * 0.5);
  --spacing1: calc(var(--spacing) * 1);
  --spacing2: calc(var(--spacing) * 2);
  --spacing3: calc(var(--spacing) * 3);
  --spacing4: calc(var(--spacing) * 4);
  --spacing5: calc(var(--spacing) * 5);
  --spacing6: calc(var(--spacing) * 6);
  --spacing7: calc(var(--spacing) * 7);
  --spacing8: calc(var(--spacing) * 8);

  --hAlign: center;
  --pAlign: center;

  --h1Size: min(80px, 8vw);
  --h1Weight: 100;

  --h2Size: min(60px, 7vw);
  --h2Weight: 200;

  --h3Size: min(50px, 6vw);
  --h3Weight: 200;

  --h4Size: 20px;
  --h4Weight: 400;

  --pSize: 20px;
  --pWeight: 300;

  --minPageWidth: '320px';
  --containerMaxWidth: min(1400px, 100vw - var(--spacing5));
  --containerPadding: calc((100vw - var(--containerMaxWidth)) / 2);
}

html,
body {
  font-family: var(--fontPrimary);
  font-size: var(--pSize);
  font-weight: var(--pWeight);
  color: var(--dark);
  min-width: var(--minPageWidth);
  height: 100%;
  line-height: 1;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

#root,
#__next {
  isolation: isolate;
  /* height: 100%; */
}

.container {
  width: 100%;
  max-width: unset;
  padding-left: var(--containerPadding);
  padding-right: var(--containerPadding);
  display: flex;
  flex-direction: column;

  &[data-size='small'] {
    --containerMaxWidth: min(800px, 100vw - var(--spacing5));
    --containerPadding: calc((100vw - var(--containerMaxWidth)) / 2);
  }

  &[data-size='medium'] {
    --containerMaxWidth: min(1200px, 100vw - var(--spacing5));
    --containerPadding: calc((100vw - var(--containerMaxWidth)) / 2);
  }
}

input,
textarea,
select {
  font-family: var(--fontPrimary);
  font-size: var(--pSize);
  font-weight: var(--pWeight);
}

h1,
h2,
h3,
h4,
p {
  @apply m-0 p-0;
  margin-top: var(--spacing2);
  font-family: var(--fontHeading);
  text-align: var(--hAlign);

  &:first-child {
    margin-top: 0;
  }
}

h1,
h2,
h3,
h4 {
  @apply uppercase;
}

h1 {
  font-size: var(--h1Size);
  font-weight: var(--h1Weight);
}

h2 {
  font-size: var(--h2Size);
  font-weight: var(--h2Weight);
}

h3 {
  font-size: var(--h3Size);
  font-weight: var(--h3Weight);
}

p, ul {
  font-family: var(--fontText);
  line-height: 1.3;
  font-size: var(--pSize);
  font-weight: var(--pWeight);
  text-align: var(--pAlign);
  text-align-last: var(--pAlign);

  img {
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    margin: var(--spacing5) 0;
  }
}

p + h3, ul + h3 {
  margin-top: var(--spacing5);
}

[data-is-article='true'] {
  p {
    text-align-last: left;
  }

  h3 {
    text-align: left;
  }
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-Thin.ttf');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}
