.wrapper {
  height: 50px;
  border: none;
  appearance: none;
  background: var(--primary);
  color: white;
  padding: 0 30px;
  width: fit-content;
  min-width: 250px;
  display: flex;
  place-content: center;
  place-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
  place-self: center;
  /* border-radius: 4px; */
  margin-top: 10px;
  font-weight: 300;
  font-family: var(--fontHeading);

  &:focus {
    outline: none;
    background: var(--primary);
    filter: brightness(1.1);
  }

  &:hover {
    filter: brightness(1.1);
  }
}
