.header {
  font-family: 'Lexend', sans-serif;
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing3);
  /* margin-bottom: var(--spacing3); */
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px) brightness(1.1);
  z-index: 100;
  max-width: 100vw;
}

.menu {
  display: flex;
  flex-direction: row;
  color: inherit;
  place-items: center;
  text-decoration: none;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  margin-left: 3vh;
  gap: var(--spacing0_5);
}

.headerMenuItem {
  display: flex;
  place-items: center;
  place-content: center;

  padding: 0 var(--spacing2);
  color: inherit;
  text-decoration: none;
  height: 40px;

  font-family: var(--fontHeading);
  text-align: var(--hAlign);
  font-size: var(--h4Size);
  font-weight: var(--h3Weight);
  color: var(--dark);
  transition: 0.2s;

  &:hover {
    color: var(--primary);
  }

  &[data-is-contact] {
    display: flex;
    background: black;
    color: white;
  }

  &[data-is-menu] {
    display: none;
    margin-right: calc(var(--spacing2) * -1);
  }
}

@media (--mobile) {
  .headerMenuItem {
    display: none;

    &[data-is-menu] {
      display: flex;
    }
  }
}

.headerLogo {
  display: block;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-family: pirulen, sans-serif;
  margin-right: auto;

  letter-spacing: 5px;
  font-size: 13px;
  font-weight: 300;

  & > div {
    letter-spacing: 11px;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 360px;
  transform: translateX(360px);
  transition: 0.2s;
  z-index: 101;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--spacing3) 0;

  .headerMenuItem {
    display: flex;
    text-align: left;
    padding: 0 var(--spacing3);
    justify-content: flex-start;
    align-content: center;
  }

  &[data-is-active='true'] {
    transform: translateX(0);
  }
}

.sidebarBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.2s;
  pointer-events: none;
  opacity: 0;
  z-index: 100;

  &[data-is-active='true'] {
    opacity: 1;
    pointer-events: all;
  }
}
