.wrapper {
  background: #111;
  color: white;
  padding: 40px 0;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing2);
}

.title {
  display: flex;
  flex: 1 1 300px;
  margin-bottom: 40px;
  flex-direction: column;
}

.tagline {
  /* margin-top: 30px; */
  font-size: 20px;
  font-weight: 300;
}

.logo {
  width: 150px;
}

.list {
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  place-items: flex-start;
}

.footerTitle {
  text-transform: uppercase;
  text-align: center;
  color: white;
  margin-bottom: var(--spacing2);

  font-family: var(--fontHeading);
  font-size: var(--h3Size);
  font-weight: var(--h3Weight);
  /* color: var(--primary); */
}

.footerLink {
  /* font-size: 20px;
  font-weight: 300; */
  display: flex;
  flex-direction: row;
  place-items: center;
  line-height: 1;
  padding: 10px 0;

  & i {
    margin-right: 10px;
  }
}

.footerBottom {
  display: flex;
  flex-direction: row;
  place-items: center;
  place-content: center;
  padding: 20px 0;
  /* font-size: 20px;
  font-weight: 300; */
  color: white;
  background: black;
}

.headerLogo {
  display: block;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-family: pirulen, sans-serif;
  margin-right: auto;

  letter-spacing: 5px;
  font-size: 30px;
  font-weight: 300;

  & > div {
    letter-spacing: 16px;
  }
}

.tagline {
  margin-top: var(--spacing2);
  max-width:250px;
}