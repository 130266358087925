.wrapper {
  @apply my-5;
}

.promos {
  @apply flex flex-row flex-wrap;

  gap: var(--spacing0_5);
}

.promo {
  @apply aspect-[16/9] bg-light relative cursor-pointer;
  flex: 1 1 260px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.9);
  opacity: 1;
  transition: 0.2s ease-in;
  pointer-events: all;
  filter: brightness(0.7);

  /* &[data-is-active='true'] {
    transition: 1.5s ease-in;
    opacity: 1;
    pointer-events: all;
  } */

  img {
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    filter: brightness(1);
  }
}

.title {
  position: absolute;
  /* left: var(--spacing3); */
  top: var(--spacing4);
  /* // border: 1px solid #fff; */
  background-color: white;
  color: var(--dark);
  text-transform: uppercase;
  padding: var(--spacing1) var(--spacing2) var(--spacing0_5);
  z-index: 5;
  transition: 0.1s;

  text-align: var(--hAlign);
  font-family: var(--fontHeading);
  font-size: var(--h3Size);
  font-weight: var(--h2Weight);
  text-transform: uppercase;
  margin-bottom: var(--spacing2);
  pointer-events: none;

  /* .wrapper:hover & {
    background: var(--primary);
    pointer-events: all;
    transform: translateX(0);
  } */
}
