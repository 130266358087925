.wrapper {
  @apply my-5;
}

.priceItems {
  @apply flex flex-row;

  gap: var(--spacing0_5);

  @media (--mobile) {
    flex-wrap: wrap;
  }
}

.priceItem {
  @apply bg-light p-3 flex flex-col place-items-center;
  flex: 1 1 100%;
}

.title {
  text-align: var(--hAlign);
  font-family: var(--fontHeading);
  font-size: var(--h3Size);
  font-weight: var(--h3Weight);
  color: var(--primary);
  text-transform: uppercase;
}

.image {
  aspect-ratio: 16/9;
  position: relative;
  width: calc(100% + var(--spacing5));
  margin-top: var(--spacing1);
}

.text {
  margin-bottom: auto;
}

.price {
  font-family: var(--fontHeading);
  font-size: var(--h2Size);
  font-weight: var(--h3Weight);
  color: var(--primary);
  margin-top: var(--spacing3);
}

.bullets {
  & .bullet {
  }
}
