.wrapper {
  margin: 50px 0;

  &[data-is-greyscale='true'] {
    filter: grayscale(100%) saturate(1.1);
  }

  &[data-is-no-wrap='true'] {
    & .mosaic {
      @apply flex-nowrap;
    }
  }

  &[data-is-letterbox='true'] {
    & .mosaicImage {
      aspect-ratio: 2/1;
    }
  }
  
  &[data-is-small-bottom-margin='true'] {
    @apply -mb-3;
  }
}

.title {
  text-align: var(--hAlign);
  font-family: var(--fontHeading);
  font-size: var(--h2Size);
  font-weight: var(--h2Weight);
  color: var(--dark);
  text-transform: uppercase;
  margin-bottom: var(--spacing2);
}

.mosaic {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: var(--spacing0_5);

  grid-auto-flow: row dense;

  position: relative;

  @apply flex flex-row flex-wrap;
}

.mosaicImage {
  grid-column: span 2;
  aspect-ratio: 3/2;
  position: relative;

  flex: 1 1 465px;

  & img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

.mosaicImage_OLD {
  grid-column: span 2;

  &:nth-child(3n + 1) {
    grid-column: span 4;
    grid-row: span 2;
  }

  &:nth-child(9n + 4) {
    grid-column: span 4;
    grid-column-end: -1;
    grid-row: span 2;
  }

  &:nth-child(9n + 7) {
    grid-column: span 6;
    grid-row: span 1;

    & img {
      aspect-ratio: 20/10;
    }
  }

  &:nth-child(9n + 8),
  &:nth-child(9n + 9) {
    grid-column: span 3;
    grid-row: span 1;
  }

  /* 1 element last child */
  &:nth-child(3n + 1):nth-last-child(1) {
    grid-column: span 6;
    grid-row: span 1;

    & img {
      aspect-ratio: 20/10;
    }
  }

  /* 2 elements last child */
  &:nth-child(3n + 2):nth-last-child(1),
  &:nth-child(3n + 1):nth-last-child(2) {
    grid-row: span 1;
    grid-column: span 3;

    & img {
      aspect-ratio: 3/2;
    }
  }

  /* 2 elements last child */
  &:nth-child(1):nth-last-child(1) {
    grid-row: span 1;
    grid-column: 1 / 7;

    & img {
      aspect-ratio: 20/10;
    }
  }

  & img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}
