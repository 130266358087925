.container {
  width: 100%;
  max-width: unset;
  padding-left: var(--containerPadding);
  padding-right: var(--containerPadding);
  display: flex;
  flex-direction: column;

  &[data-size='small'] {
    --containerMaxWidth: min(800px, 100vw - var(--spacing5));
    --containerPadding: calc((100vw - var(--containerMaxWidth)) / 2);
  }

  &[data-size='medium'] {
    --containerMaxWidth: min(1200px, 100vw - var(--spacing5));
    --containerPadding: calc((100vw - var(--containerMaxWidth)) / 2);
  }
}
