.wrapper {
  @apply my-5;
  overscroll-behavior: none auto;
}

.title {
  @apply font-heading font-light text-primary uppercase text-h1;

  text-align: var(--hAlign);
  font-size: var(--h2Size);
  font-weight: var(--h2Weight);
  color: var(--dark);
  text-transform: uppercase;
}

.images {
  --maxWidth: var(--containerMaxWidth, min(100vw - 80px, 1220px));
  --scrollPadding: calc((100vw - var(--maxWidth)) / 2);

  @apply relative overflow-hidden mt-[var(--spacing2)] w-[100vw] px-[var(--scrollPadding)];
}

.placeholder {
  @apply w-full aspect-[20/10];
}

.image {
  --relativePosition: 0;
  width: var(--maxWidth);

  @apply absolute aspect-[20/10] overflow-hidden opacity-0 z-[1];
  @apply will-change-[opacity,transform] select-none duration-300 ease-out;

  &[data-relative-position='0'] {
    @apply z-10 opacity-100;

    & .img {
      @apply blur-0 brightness-100 saturate-100;
    }

    & .imageTitle {
      @apply translate-x-0 opacity-100;
    }

    & .imageDescription {
      @apply translate-y-0 opacity-100;
    }
  }
}

.img {
  @apply w-full aspect-[20/10] object-cover object-center relative;
  @apply cursor-pointer duration-1000;
  @apply blur-[10px] brightness-[0.8] saturate-[200%] cursor-pointer;

  & img {
    @apply aspect-[20/10] object-cover object-center pointer-events-none;
  }
}

.nav {
  @apply absolute bottom-0 right-0 flex flex-row gap-[2px] z-[12];

  & .navItem {
    @apply h-[50px] w-[50px] flex place-content-center place-items-center;
    @apply text-[white] cursor-pointer text-h2 duration-200;
    @apply bg-[black] bg-opacity-40;

    svg {
      @apply h-[30px];
    }

    &:hover {
      @apply bg-[black] bg-opacity-60;
    }

    &:active {
      @apply bg-primary;
    }
  }
}

.imageContent {
  @apply absolute bottom-[30px] left-[30px] flex flex-col;
  @apply gap-[2px] z-[12] duration-200 ease-out;

  place-items: flex-start;
}

.imageTitle {
  @apply bg-[white] bg-opacity-[95%] text-dark py-1 px-3 uppercase -translate-x-5 duration-[400ms] ease-out will-change-transform;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 400;
}

.imageDescription {
  @apply bg-[white] bg-opacity-[95%] text-dark py-1 px-3 uppercase translate-y-5 duration-[400ms] ease-out will-change-transform;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.3;
  max-width: 600px;
}
